import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

import { Ad } from "../../generated/graphql";

import "./ad.scss";

import Loading from "../utilities/Loading";

import AdForm from "./AdForm";

interface ParamTypes {
  id: string;
}

interface AdViewProps {
  refetch?: () => void;
}

const AdView = ({ refetch = () => {} }: AdViewProps) => {
  const { id } = useParams<ParamTypes>();

  const adId = id;

  if (adId === "new") {
    return <AdForm refetch={refetch} />;
  }

  const AD_QUERY = gql`
    query SingleAd($ad_id: String!) {
      ad(ad_id: $ad_id) {
        ad_id
        ad_name
        url
        image
        copy
      }
    }
  `;

  const { data, loading, error } = useQuery<{ ad: Ad }>(AD_QUERY, {
    variables: { ad_id: adId },
  });

  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;
  if (!data) return <p>Not found</p>;
  if (data.ad === null) return <p>No ad found</p>;

  return <AdForm ad={data.ad} refetch={refetch} />;
};

export default AdView;
