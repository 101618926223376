import { Switch, Route, Redirect } from "react-router-dom";
import {
  ApolloClient,
  NormalizedCacheObject,
  ApolloProvider,
} from "@apollo/client";

import { useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import { cache } from "./cache";
import Container from "./components/Container";
import Navbar from "./components/navbar/Navbar";
import AppRegisterView from "./components/app/AppRegisterView";
import AdView from "./components/ad/AdsView";

const { REACT_APP_GRAPHQL_URI } = process.env;

const App = () => {
  const [token, setState] = useState("");

  const { getAccessTokenSilently } = useAuth0();

  // Initialize ApolloClient
  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache,
    uri: REACT_APP_GRAPHQL_URI,
    headers: {
      authorization: `Bearer ${token}` || "",
    },
  });

  const getToken = async () => {
    const tempToken = await getAccessTokenSilently();
    setState(tempToken);
  };

  getToken();

  return (
    <>
      <ApolloProvider client={client}>
        <Navbar />
        <Container>
          <Switch>
            <Route path="/apps" component={AppRegisterView} />
            <Route path="/ads" component={AdView} />
            <Route path="/">
              <Redirect to="/apps" />
            </Route>
          </Switch>
        </Container>
      </ApolloProvider>
    </>
  );
};

export default App;
