import { gql, useQuery } from "@apollo/client";

import { Switch, Route } from "react-router-dom";

import { App, Datum, User } from "../../generated/graphql";

import "../../main.scss";

import AppTable from "./AppTable";
// import { BarView, BarData } from "../BarView";
// import { BarData } from "../BarView";
// import { CountryView, CountryData } from "../CountryView";
// import { CountryData } from "../CountryView";
// import { PieData } from "../PieView";
// import { LineView, LineData } from "../LineView";
// import { LineData } from "../LineView";
import CreateAppForm from "./CreateAppForm";
import OwnAppDetailView from "./OwnAppDetailView";
import LineChart from "../charts/Line/LineChart";

import ResponsiveCountryTooltip from "../charts/CountryChart/CountryTooltip";

const APP_QUERY = gql`
  query {
    apps {
      app_id
      app_name
      api_key
      apple_id
      url
      image_url
      countries {
        label
        value
      }
      devices {
        label
        value
      }
      versions {
        label
        value
      }
      usage {
        label
        value
      }
    }
    user {
      user_name
      countries {
        label
        value
      }
      devices {
        label
        value
      }
      versions {
        label
        value
      }
      usage {
        label
        value
      }
    }
  }
`;

const AppRegisterView = () => {
  const { data, loading, error, refetch } =
    useQuery<{ apps: App[]; user: User }>(APP_QUERY);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error.message}</div>;
  if (!data) return <p>Not found</p>;
  if (data.user === null) return <p>No user found</p>;

  console.log(data.user);

  const usage: Datum[] =
    data.user.usage !== null ? (data.user.usage as Datum[]) : [];

  // const versions: Datum[] =
  //   data.user.versions !== null ? (data.user.versions as Datum[]) : [];

  // const devices: Datum[] =
  //   data.user.devices !== null ? (data.user.devices as Datum[]) : [];

  const countries: Datum[] =
    data.user.countries !== null ? (data.user.countries as Datum[]) : [];

  return (
    <div>
      <Switch>
        <Route path="/apps/:id" component={OwnAppDetailView} />
        <Route path="/apps/">
          <div className="row">
            <div className="col-sm-4">
              {/* <LineView contents={users} /> */}
              <div className="card" style={{ width: "100%" }}>
                <div className="card-header bg-white">
                  <h4>Users</h4>
                </div>
                <div className="card-body" style={{ height: 400 }}>
                  <LineChart data={usage} />
                </div>
              </div>
            </div>
            {/* <div className="col-sm-4">
              <BarView contents={devicesData} />
            </div>
            <div className="col-sm-4">
              <PieView contents={dummyData.versions} />
            </div> */}
            <div className="col-sm-8">
              <div className="card" style={{ width: "100%" }}>
                <div className="card-header bg-white">
                  <h4>Countries</h4>
                </div>
                <div className="card-body" style={{ height: 400 }}>
                  <ResponsiveCountryTooltip data={countries} />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row mt-3"> */}
          {/* <div className="card-body">
              <CountryView contents={dummyData.countries} />
            </div> */}

          {/* </div> */}

          <div className="card mt-3" style={{ width: "100%" }}>
            <div className="card-header bg-white">
              <div className="d-flex w-100 align-items-center justify-content-between">
                <div>
                  <h3 className="new-card-title mb-0">Apps</h3>
                </div>
                <div>
                  <CreateAppForm refetch={refetch} />
                </div>
              </div>
            </div>
            <div className="card-body">
              {data !== undefined ? <AppTable apps={data.apps} /> : null}
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  );
};

export default AppRegisterView;
