import "../main.scss";

/**
 * Primary UI component for user interaction
 */
import Icon from "./Icon";

export interface ButtonProps {
  backgroundColor?: string;
  icon?: string;
  label: string;
  buttonClass?: string;
  clickHandler: () => any;
}

const Button = ({
  backgroundColor = "",
  icon,
  label,
  buttonClass = "btn btn-primary",
  clickHandler,
}: ButtonProps) => {
  const handleClick = () => {
    clickHandler();
  };
  return (
    <button
      onClick={handleClick}
      type="button"
      className={buttonClass}
      style={{ backgroundColor }}
    >
      {icon ? (
        <Icon
          width="1.1rem"
          height="1.1rem"
          name={icon}
          className="spacer-right"
        />
      ) : null}
      {label}
    </button>
  );
};

export default Button;
