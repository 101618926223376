import {
  TooltipWithBounds,
  useTooltip,
  useTooltipInPortal,
  defaultStyles,
} from "@visx/tooltip";

import { scaleQuantize } from "@visx/scale";
import { Mercator, Graticule } from "@visx/geo";

import { ParentSize } from "@visx/responsive";

import * as topojson from "topojson-client";
import topology from "./world-topo.json";

import { Datum } from "../../../generated/graphql";

import "./CountryTooltip.scss";

type TooltipData = string;

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: "rgba(53,71,125,0.8)",
  color: "white",
  width: 152,
  height: 72,
  padding: 12,
};

export const background = "white";

export type GeoMercatorProps = {
  width: number;
  height: number;
  events?: boolean;
};

interface FeatureShape {
  type: "Feature";
  id: string;
  geometry: { coordinates: [number, number][][]; type: "Polygon" };
  properties: { name: string };
}

const world = topojson.feature(topology, topology.objects.units) as {
  type: "FeatureCollection";
  features: FeatureShape[];
};

export type TooltipProps = {
  width: number;
  height: number;
  showControls?: boolean;
  data: Datum[];
};

const CountryTooltip = ({
  width,
  height,
  data,
}: //   showControls = true,
TooltipProps) => {
  const { containerRef, containerBounds } = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  });

  const {
    showTooltip,
    // hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip<TooltipData>({
    // initial tooltip state
    tooltipOpen: true,
    tooltipLeft: width / 3,
    tooltipTop: height / 3,
    tooltipData: "Move me with your mouse or finger",
  });

  const TooltipComponent = TooltipWithBounds;

  const centerX = width / 2;
  const centerY = height / 2;
  const scale = (width / 630) * 100;

  const details: { [id: string]: number } = data.reduce(
    (a, x) => ({
      ...a,
      [x.label!]: x.value !== undefined ? (x.value as number) : 0,
    }),
    {} as { [id: string]: number }
  );

  const color = scaleQuantize({
    domain: [
      Math.min(...Object.values(details)),
      Math.max(...Object.values(details)) > 0
        ? Math.max(...Object.values(details))
        : 100,
    ],
    range: [
      "#ddd6fe",
      "#c4b5fd",
      "#a78bfa",
      "#8b5cf6",
      "#7c3aed",
      "#6d28d9",
      "#5b21b6",
      "#4c1d95",
    ],
  });

  return (
    <>
      <div ref={containerRef} className="tooltip-example">
        {width < 10 ? null : (
          <svg width={width} height={height}>
            <rect
              x={0}
              y={0}
              width={width}
              height={height}
              fill={background}
              rx={14}
            />
            <Mercator<FeatureShape>
              data={world.features}
              scale={scale}
              translate={[centerX, centerY + 50]}
            >
              {(mercator) => (
                <g>
                  <Graticule
                    graticule={(g) => mercator.path(g) || ""}
                    stroke="rgba(33,33,33,0.05)"
                  />
                  {mercator.features.map(({ feature, path }, i) => (
                    <path
                      key={`map-feature-${i}`} // eslint-disable-line
                      d={path || ""}
                      fill={color(
                        details[feature.id] !== undefined
                          ? details[feature.id]
                          : 0
                      )}
                      stroke="#FFFFFF"
                      strokeWidth={0.5}
                      onClick={() => {
                        console.log(feature);
                      }}
                      onMouseMove={(
                        event: React.MouseEvent<SVGPathElement>
                      ) => {
                        // console.log(event);
                        // console.log(feature);
                        const containerX =
                          ("clientX" in event ? event.clientX : 0) -
                          containerBounds.left;
                        const containerY =
                          ("clientY" in event ? event.clientY : 0) -
                          containerBounds.top;
                        showTooltip({
                          tooltipLeft: containerX,
                          tooltipTop: containerY,
                          tooltipData: `${feature.properties.name} ${
                            details[feature.id] !== undefined
                              ? details[feature.id]
                              : "0"
                          }`,
                        });
                        // setTooltipMessage(
                        //   `Hovered: ${feature.properties.name} (${
                        //     feature.id
                        //   }) ${details[feature.id]} ${feature}`
                        // );
                      }}
                    />
                  ))}
                </g>
              )}
            </Mercator>
          </svg>
        )}
        {tooltipOpen ? (
          <>
            {/* <div
              className="position-indicator"
              style={{
                transform: `translate(${
                  tooltipLeft - positionIndicatorSize / 2
                }px, ${tooltipTop - positionIndicatorSize / 2}px)`,
              }}
            /> */}
            {/* <div
              className="crosshair horizontal"
              style={{ transform: `translateY(${tooltipTop}px)` }}
            />
            <div
              className="crosshair vertical"
              style={{ transform: `translateX(${tooltipLeft}px)` }}
            /> */}
            <TooltipComponent
              key={Math.random()} // needed for bounds to update correctly
              left={tooltipLeft}
              top={tooltipTop}
              style={tooltipStyles}
            >
              {tooltipData}
            </TooltipComponent>
          </>
        ) : (
          <div className="no-tooltip">
            Move or touch the canvas to see the tooltip
          </div>
        )}
      </div>
    </>
  );
};

export interface ResponsiveCountryTooltipProps {
  data: Datum[];
}

const ResponsiveCountryTooltip = ({ data }: ResponsiveCountryTooltipProps) => (
  <ParentSize>
    {(parent) => (
      <CountryTooltip height={parent.height} width={parent.width} data={data} />
    )}
  </ParentSize>
);

export default ResponsiveCountryTooltip;
