import { gql, useQuery } from "@apollo/client";

import { RouteComponentProps, withRouter } from "react-router-dom";

import Loading from "../utilities/Loading";
import { App, Category, Datum } from "../../generated/graphql";
// import { BarView, BarData } from "../BarView";
// import { CountryView, CountryData } from "../CountryView";
// import { PieView, PieData } from "../PieView";
// import { LineView, LineData } from "../LineView";
import LineChart from "../charts/Line/LineChart";
import EditAppForm from "./EditAppForm";
import DomainInfo from "./DomainInfo";

import draft from "../../assets/img/Draft App Icon.jpg";
import PieChart from "../charts/Pie/PieChart";
import ResponsiveCountryTooltip from "../charts/CountryChart/CountryTooltip";
import AppAdvertisingSettings from "./AppAdvertisingSettings/AppAdvertisingSettings";

type OwnAppDetailViewParams = {
  id: string; // parameters will always be a string (even if they are numerical)
};
type OwnAppDetailViewProps = RouteComponentProps<OwnAppDetailViewParams>;

const OwnAppDetailView = (props: OwnAppDetailViewProps) => {
  const { match } = props;

  const appId = match.params.id;

  const APP_QUERY = gql`
    query SingleApp($app_id: String!) {
      app(app_id: $app_id) {
        app_id
        app_name
        apple_id
        image_url
        url
        api_key
        countries {
          label
          value
        }
        devices {
          label
          value
        }
        versions {
          label
          value
        }
        usage {
          label
          value
        }
        domain {
          domain_name
          token
          verified
        }
        advertising
        cross_promotion
        ad {
          ad_id
        }
        blocklist_categories {
          category_id
          category_name
        }
      }
    }
  `;

  const { data, loading, error, refetch } = useQuery<{ app: App }>(APP_QUERY, {
    variables: { app_id: appId },
  });

  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;
  if (!data) return <p>Not found</p>;
  if (data.app === null) return <p>No app found</p>;

  console.log(data.app);

  console.log(data.app.usage);

  const usage: Datum[] =
    data.app.usage !== null ? (data.app.usage as Datum[]) : [];

  const versions: Datum[] =
    data.app.versions !== null ? (data.app.versions as Datum[]) : [];

  const devices: Datum[] =
    data.app.devices !== null ? (data.app.devices as Datum[]) : [];

  const countries: Datum[] =
    data.app.countries !== null ? (data.app.countries as Datum[]) : [];

  return (
    <div className="container my-3">
      <div className="row align-items-start">
        <div className="d-flex ">
          <div className="flex-shrink-0">
            <img
              src={
                (data.app.image_url?.length ?? 0) > 0
                  ? data.app.image_url!
                  : draft
              }
              alt={`${data.app.app_name} logo`}
              className="app-image-medium"
            />
          </div>
          <div className="d-flex flex-grow-1 ms-3">
            <div className="d-flex flex-column">
              <div className="d-flex">
                <h2 className="new-card-title mb-0">{data.app.app_name}</h2>
                <div>
                  <DomainInfo
                    domainName={data.app.domain?.domain_name ?? ""}
                    token={data.app.domain?.token ?? ""}
                    verified={data.app.domain?.verified ?? false}
                    apple_id={data.app.apple_id ?? ""}
                  />
                </div>
              </div>
              <h6 className="mt-auto">API key: {data.app.api_key}</h6>
            </div>

            <div className="ms-auto">
              <EditAppForm
                refetch={refetch}
                appId={appId}
                propAppName={data.app.app_name}
                propAppleId={data.app.apple_id ?? ""}
              />
            </div>
          </div>
        </div>

        {/* <div className="col-10">
          <div className="d-flex">
            
          </div>
        </div> */}
      </div>

      <AppAdvertisingSettings
        appId={data.app.app_id}
        initialAdvertising={data.app.advertising ?? false}
        initialCrossPromotion={data.app.cross_promotion ?? false}
        initialAdId={data.app.ad?.ad_id}
        initialBlocklistCategories={
          data.app.blocklist_categories?.map((a) => a as Category) ?? []
        }
      />

      {/* <div className="d-flex row w-100 align-items-center justify-content-between"> */}
      {/* <div className="col-6">
          <h6>Apple ID: {data.app.apple_id ?? "N/A"}</h6>
        </div> */}
      {/* <div className="col-12">
          <h6>API key: {data.app.api_key}</h6>
        </div>
      </div> */}

      <div className="row mt-3">
        <div className="col-sm-4">
          <div className="card" style={{ width: "100%" }}>
            <div className="card-header bg-white">
              <h4>Users</h4>
            </div>
            <div className="card-body" style={{ height: 400 }}>
              <LineChart data={usage} />
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          {/* <BarView contents={devices} /> */}
          <div className="card" style={{ width: "100%" }}>
            <div className="card-header bg-white">
              <h4>Devices</h4>
            </div>
            <div className="card-body" style={{ height: 400 }}>
              <PieChart data={devices} />
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card" style={{ width: "100%" }}>
            <div className="card-header bg-white">
              <h4>Versions</h4>
            </div>
            <div className="card-body" style={{ height: 400 }}>
              <PieChart data={versions} />
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-sm-12">
          <div className="card" style={{ width: "100%" }}>
            <div className="card-header bg-white">
              <h4>Countries</h4>
            </div>
            <div className="card-body" style={{ height: 400 }}>
              <ResponsiveCountryTooltip data={countries} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(OwnAppDetailView);
