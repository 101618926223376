import { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useFieldArray, useForm } from "react-hook-form";
import {
  Ad,
  Category,
  EditAppAdvertisingInput,
} from "../../../generated/graphql";
import {
  CATEGORY_AND_ADS_QUERY,
  EDIT_APP_ADVERTISING,
} from "../../../api/queries";
import AdPreview from "../../ad/AdPreview";

interface AppAdvertisingSettingsProps {
  appId: string;
  initialAdvertising: boolean;
  initialCrossPromotion: boolean;
  initialAdId: number | undefined;
  initialBlocklistCategories: Category[];
}

const AppAdvertisingSettings = ({
  appId = "",
  initialAdvertising = false,
  initialCrossPromotion = false,
  initialAdId = undefined,
  initialBlocklistCategories = [],
}: AppAdvertisingSettingsProps): JSX.Element => {
  const {
    control,
    register,
    handleSubmit,
    formState,
    watch,
    setValue,
    reset,
    trigger,
  } = useForm({
    defaultValues: {
      selectedAd: initialAdId,
      genreBlockList: initialBlocklistCategories,
      advertisingEnabled: initialAdvertising,
      crossPromotionEnabled: initialCrossPromotion,
    },
  });
  useFieldArray({ name: "genreBlockList", control });

  const [selectedAd, setSelectedAd] = useState<Ad | undefined>(undefined);

  const [genreResults, setGenreResults] = useState<Array<Category>>([]);
  const [showAll, setShowAll] = useState(false);

  const [editAppAdvertising] =
    useMutation<EditAppAdvertisingInput>(EDIT_APP_ADVERTISING);

  const saveAppAdvertisingDetails = async (formValues) => {
    const genreBlockList = watch("genreBlockList");
    const genreBlockListIds: string[] = genreBlockList.map(
      (a) => a.category_id as string
    );

    const selectedAdId = selectedAd?.ad_id;

    try {
      await editAppAdvertising({
        variables: {
          appId,
          advertising: formValues.advertisingEnabled,
          crossPromotion: formValues.crossPromotionEnabled,
          genreBlockListIds,
          selectedAdId,
        },
      });
      reset(formValues);
    } catch {
      // eslint-disable-next-line no-console
      console.warn("failed to update details");
    }
  };

  const { data, loading, error } = useQuery<{
    categories: Category[];
    ads: Ad[];
  }>(CATEGORY_AND_ADS_QUERY);

  let genreOptions: Category[] = [];
  let ads: Ad[] = [];

  if (data !== undefined) {
    genreOptions = data.categories;
    ads = data.ads;
  }

  useEffect(() => {
    const foundAd = ads.filter((a) => a.ad_id === initialAdId);
    if (foundAd.length > 0) {
      setSelectedAd(foundAd[0]);
    } else setSelectedAd(undefined);
  }, [ads]);

  let searchString = "";

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error.message}</div>;
  if (!data) return <p>Not found</p>;

  const addGenreToBlocklist = (genre: Category) => {
    setValue("genreBlockList", [...watch("genreBlockList"), genre], {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const removeGenreFromBlocklist = (genre: Category) => {
    const newBlockList = [
      ...watch("genreBlockList").filter(
        (blockListGenre) => genre.category_id !== blockListGenre.category_id
      ),
    ];
    setValue("genreBlockList", newBlockList, {
      shouldValidate: true,
      shouldDirty: true,
    });
    trigger("genreBlockList");
  };

  const filterResults = (all?: boolean) => {
    const localShowAll = all === undefined ? showAll : all;

    if (searchString.length < 1) {
      if (localShowAll) {
        setGenreResults(
          genreOptions.filter(
            (genre) => watch("genreBlockList").indexOf(genre) < 0
          )
        );
      } else {
        setGenreResults([]);
      }
    } else {
      const remainingGenres = genreOptions.filter(
        (genre) => watch("genreBlockList").indexOf(genre) < 0
      );

      console.log(remainingGenres);

      setGenreResults(
        remainingGenres.filter(
          ({ category_name }: Category) =>
            (category_name?.toLowerCase().indexOf(searchString.toLowerCase()) ??
              -2) > -1
        )
      );
    }
  };

  return (
    <div className="col-12">
      <form onSubmit={handleSubmit(saveAppAdvertisingDetails)}>
        <div className="card mt-3" style={{ width: "100%" }}>
          <div className="card-header bg-white">
            <div className="row">
              <div className="col-9">
                <div className="row">
                  <div className="col-9">
                    <h4>Advertising</h4>
                  </div>
                  <div className="col-3">
                    <button
                      className={
                        !formState.isDirty
                          ? "disabled btn btn-outline-secondary btn-sm me-1 mt-1"
                          : "btn btn-outline-success btn-sm me-1 mt-1"
                      }
                      type="submit"
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-3">
                <div className="form-check form-switch">
                  <label className="form-check-label" htmlFor="advertising">
                    Enabled
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="advertisingEnabled"
                      {...register("advertisingEnabled")}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-9">
                <strong>Cross Promotion</strong>
              </div>
              <div className="col-3">
                <div className="form-check form-switch">
                  <label className="form-check-label" htmlFor="advertising">
                    Enabled
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="crossPromotion"
                      {...register("crossPromotionEnabled")}
                    />
                  </label>
                </div>
              </div>
            </div>

            <p>
              Opt-in to the Not Evil Ads cross-promotion program to show ads for
              other apps if yours is not currently sponsored, and in return have
              your app be advertised by others.
            </p>

            <div className="row">
              <div className="col-6">
                <div>
                  <strong>Genre blocklist:</strong>
                  <div>
                    {watch("genreBlockList").length === 0 ? (
                      <p>All genres allowed</p>
                    ) : (
                      watch("genreBlockList").map(
                        ({ category_id, category_name }) => (
                          <button
                            className="btn btn-outline-warning btn-sm me-1 mt-1"
                            key={category_id}
                            type="button"
                            id={category_id!}
                            onClick={() => {
                              removeGenreFromBlocklist({
                                category_id,
                                category_name,
                              });
                            }}
                          >
                            {category_name}
                          </button>
                        )
                      )
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-8">
                    <strong>Add genre to blocklist:</strong>
                  </div>
                  <div className="col-4">
                    <div className="form-check form-switch">
                      <label className="form-check-label" htmlFor="advertising">
                        Show all
                        <input
                          onChange={(e) => {
                            setShowAll(e.target.checked);
                            filterResults(e.target.checked);
                          }}
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={showAll}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <input
                  onChange={(e) => {
                    searchString = e.target.value;
                    filterResults();
                  }}
                  autoComplete="off"
                  key="genreSearch"
                  type="text"
                  className="form-control"
                  id="genreSearch"
                  placeholder="Filter genres" // TODO: fun random name generator
                  // ref={formInput}
                />
                <div>
                  {genreResults
                    .filter(
                      (genre) =>
                        !watch("genreBlockList")
                          .map((blockListGenre) => blockListGenre.category_id)
                          .some((id) => id === genre.category_id)
                    )
                    .map((genre) => (
                      <button
                        className="btn btn-outline-secondary btn-sm me-1 mt-1"
                        type="button"
                        key={genre.category_id}
                        onClick={() => {
                          addGenreToBlocklist(genre);
                        }}
                      >
                        {genre.category_name}
                      </button>
                    ))}
                </div>
              </div>
              <div className="col-6">
                <strong>App ad:</strong>
                <br />
                <select
                  className="form-select"
                  aria-label="select example"
                  onChange={(event) => {
                    const match = ads.filter(
                      (a) => a.ad_id === +event.target.value
                    );
                    if (match.length > 0) {
                      setSelectedAd(match[0]);
                      formState.isDirty = true;
                    }
                  }}
                >
                  <option value="" disabled selected>
                    Select ad
                  </option>
                  {ads.map((ad) => (
                    <option key={ad.ad_id} value={ad.ad_id}>
                      {ad.ad_name}
                    </option>
                  ))}
                </select>
                <br />
                {selectedAd !== undefined ? (
                  <AdPreview
                    adImage={selectedAd.image}
                    adCopy={selectedAd.copy}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AppAdvertisingSettings;
