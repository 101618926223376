import { useState, useRef } from "react";

import { gql, useMutation } from "@apollo/client";

import * as GraphQLTypes from "../../generated/graphql";

import Modal from "../modal/Modal";

const REGISTER_APP = gql`
  mutation CreateApp($app_name: String!, $apple_id: String) {
    createApp(app_name: $app_name, apple_id: $apple_id) {
      app_name
    }
  }
`;

interface CreateAppFormProps {
  refetch?: () => void;
}

const CreateAppForm = ({
  refetch = () => {},
}: CreateAppFormProps): JSX.Element => {
  const button = {
    label: "New app",
    icon: "plus-circle",
    class: "btn btn-outline-primary",
  };
  const details = {
    title: "Register app",
    dismiss: "Cancel",
    save: "Register",
  };

  const [appName, setAppName] = useState("");
  const [appleId, setAppleId] = useState("");

  const [createApp] = useMutation<GraphQLTypes.CreateAppInput>(REGISTER_APP);

  const registerApp = async () => {
    await createApp({ variables: { app_name: appName, apple_id: appleId } });
    refetch();
  };

  const formInput = useRef(null);

  return (
    <div>
      <Modal
        button={button}
        details={details}
        onSave={() => {
          registerApp();
        }}
        inputRef={formInput}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            registerApp();
          }}
          className="g-3 text-left"
        >
          <div className="col-12">
            <label htmlFor="appName" className="form-label">
              App Name
              <input
                onChange={(e) => {
                  setAppName(e.target.value);
                }}
                key="appName"
                value={appName}
                type="text"
                className="form-control"
                id="appName"
                placeholder="Best App" // TODO: fun random name generator
                ref={formInput}
              />
            </label>
          </div>

          <div className="col-12">
            <label htmlFor="appleID" className="form-label">
              Apple ID (from App Store page)
              <input
                key="appleId"
                type="text"
                value={appleId}
                className="form-control"
                id="appleID"
                placeholder="1495577605"
                onChange={(e) => {
                  setAppleId(e.target.value);
                }}
              />
            </label>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default CreateAppForm;
