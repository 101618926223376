import "../main.scss";
import BootstrapIcons from "../assets/img/icons/bootstrap-icons.svg";

export interface IconProps {
  name: string;
  height: string;
  width: string;
  className?: string;
}

const Icon = ({
  name = "shop",
  width = "24px",
  height = "24px",
  className = "",
}: IconProps) => (
  <svg width={width} height={height} className={className}>
    <use xlinkHref={`${BootstrapIcons}#${name}`} />
  </svg>
);

export default Icon;
