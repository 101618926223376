import { gql, useQuery } from "@apollo/client";

import { Switch, Route, Link } from "react-router-dom";

import { Ad } from "../../generated/graphql";

import AdView from "./AdView";
import AdPreview from "./AdPreview";
import Button from "../Button";

import "./ad.scss";

const AD_QUERY = gql`
  query {
    ads {
      ad_id
      ad_name
      url
      image
      copy
    }
  }
`;

const AdsView = () => {
  // const { data, loading, error, refetch } = useQuery<{ ads: Ad[] }>(AD_QUERY);
  const { data, loading, error, refetch } = useQuery<{ ads: Ad[] }>(AD_QUERY);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error.message}</div>;

  return (
    <div className="container">
      <Switch>
        <Route path="/ads/:id">
          <AdView refetch={refetch} />
        </Route>
        <Route path="/ads/">
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div>
              <h3 className="new-card-title mb-0">Ads</h3>
            </div>
            <div>
              <Link to="/ads/new">
                <Button
                  label="New ad"
                  icon="plus-circle"
                  buttonClass="btn btn-outline-primary"
                  clickHandler={() => {}}
                />
              </Link>
            </div>
          </div>
          <div className="row g-3 mt-3">
            {data?.ads.map((ad) => (
              <div className="col-xl-6 col-lg-8 col-sm-12">
                <Link to={`/ads/${ad.ad_id}`}>
                  <AdPreview adImage={ad.image} adCopy={ad.copy} />
                </Link>
              </div>
            ))}
          </div>
        </Route>
      </Switch>
    </div>
  );
};

export default AdsView;
