import { NavLink } from "react-router-dom";

import "./navbar.scss";

interface NavbarItemProps {
  label: string;
  destination: string;
}

const NavbarItem = ({
  label = "Search",
  destination = "/search",
}: NavbarItemProps) => (
  <li className="nav-item">
    <NavLink
      exact
      to={destination}
      className="nav-link navbar-link"
      activeClassName="active"
    >
      {label}
    </NavLink>
  </li>
);

export default NavbarItem;
