import { useAuth0 } from "@auth0/auth0-react";

// import Icon from "../Icon";
// import Dropdown from "../dropdown/Dropdown";
// import DropdownItem from "../dropdown/DropdownItem";
// import DropdownButton from "../dropdown/DropdownButton";

import NavbarItem from "./NavbarItem";

import "./navbar.scss";

const Navbar = () => {
  const { logout } = useAuth0();

  return (
    <nav className="navbar navbar-expand-lg navbar-dark  container-fluid">
      <a className="navbar-brand logotype" href="/">
        <span className="logotype-ads">not evil</span> ads
      </a>

      <ul className="navbar-nav">
        <NavbarItem label="Apps" destination="/apps" />
        <NavbarItem label="Ads" destination="/ads" />
      </ul>

      <ul className="navbar-nav ms-auto">
        <li className="nav-item">
          <button
            onClick={() => logout()}
            type="button"
            className="btn nav-link navbar-link"
          >
            Logout
          </button>
        </li>
      </ul>

      {/* <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link" href="/notifications">
                <Icon width="1.2rem" height="1.2rem" name="bell" />
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/inbox">
                <Icon width="1.2rem" height="1.2rem" name="inbox" />
              </a>
            </li>

            <Dropdown label={user.email}>
              <>
                <DropdownItem destination="/account" icon="person">
                  Account
                </DropdownItem>
                <DropdownItem destination="/billing" icon="credit-card">
                  Billing
                </DropdownItem>
                <DropdownItem destination="/settings" icon="gear">
                  Settings
                </DropdownItem>
                <DropdownItem destination="/support" icon="life-preserver">
                  Support
                </DropdownItem>
                <div className="dropdown-divider" />
                <div className="dropdown-item logout">
                  <DropdownButton
                    icon="box-arrow-right"
                    onClick={() => logout()}
                  >
                    Log out
                  </DropdownButton>
                </div>
              </>
            </Dropdown>
          </ul>
        </div>
      </div> */}
    </nav>
  );
};

export default Navbar;
