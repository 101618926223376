import { useState, useEffect, useRef } from "react";
// import { useHistory } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";

import { Tooltip } from "bootstrap";

import Icon from "../Icon";

import { Ad } from "../../generated/graphql";
import * as GraphQLTypes from "../../generated/graphql";

import "./ad.scss";

import AdPreview from "./AdPreview";

import { ALLOWED_LENGTH } from "./AdConstants";

interface AdFormProps {
  ad?: Ad;
  refetch?: () => void;
}

const AdForm = ({ ad, refetch = () => {} }: AdFormProps) => {
  const adNamePlaceholder = "My Ad";
  const adDestinationPlaceholder = "https://notevilads.com/referral";
  // Happy things collection on Unsplash
  // https://unsplash.com/collections/6758574/happy-things
  // inoffensive pleasant photos for initial load
  const adImagePlaceholder =
    "https://source.unsplash.com/collection/6758574/300x300";
  const adCopyPlaceholder =
    "Your text goes here! Give your audience a reason to click, like a special offer";

  const [adName, setAdName] = useState(ad ? ad?.ad_name : "");
  const [adCopy, setAdCopy] = useState(ad ? ad?.copy : "");
  const [adUrl, setAdUrl] = useState(ad ? ad?.url : "");
  const [adImage, setAdImage] = useState(ad ? ad?.image : "");

  let textLengthClass = "text-success";
  if (adCopy.length > ALLOWED_LENGTH) {
    textLengthClass = "text-danger fw-bold";
  } else if (adCopy.length > ALLOWED_LENGTH - 15) {
    textLengthClass = "text-warning";
  }

  const nameTooltipRef = useRef(null);
  const imageTooltipRef = useRef(null);
  const destinationTooltipRef = useRef(null);
  const contentTooltipRef = useRef(null);

  function isValidHttpUrl(string: string) {
    let url: any;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  const [submitted, setSubmitted] = useState(false);

  const adNameValid = adName.length > 0;
  const adImageValid = isValidHttpUrl(adImage);
  const adUrlValid = isValidHttpUrl(adUrl);
  const adCopyValid = adCopy.length > 0;

  let adNameClass = "form-control";
  let adImageClass = "form-control";
  let adUrlClass = "form-control";
  let adCopyClass = "form-control";

  if (submitted === true) {
    adNameClass = `form-control ${adNameValid ? "is-valid" : "is-invalid"}`;
    adImageClass = `form-control ${adImageValid ? "is-valid" : "is-invalid"}`;
    adUrlClass = `form-control ${adUrlValid ? "is-valid" : "is-invalid"}`;
    adCopyClass = `form-control ${adCopyValid ? "is-valid" : "is-invalid"}`;
  }

  const REGISTER_AD = gql`
    mutation CreateAd(
      $ad_name: String!
      $copy: String!
      $url: String!
      $image: String!
    ) {
      createAd(ad_name: $ad_name, copy: $copy, url: $url, image: $image) {
        ad_name
      }
    }
  `;

  const EDIT_AD = gql`
    mutation EditAd(
      $ad_id: String!
      $ad_name: String!
      $copy: String!
      $url: String!
      $image: String!
    ) {
      editAd(
        ad_id: $ad_id
        ad_name: $ad_name
        copy: $copy
        url: $url
        image: $image
      ) {
        ad_name
      }
    }
  `;

  const DELETE_AD = gql`
    mutation DeleteAd($ad_id: String!) {
      deleteAd(ad_id: $ad_id)
    }
  `;

  const [createAd] = useMutation<GraphQLTypes.CreateAppInput>(REGISTER_AD);
  const [editAd] = useMutation<GraphQLTypes.CreateAppInput>(EDIT_AD);

  //   const history = useHistory();

  const validateInput = async () => {
    setSubmitted(true);

    if (adNameValid && adImageValid && adUrlValid && adCopyValid) {
      if (ad !== undefined) {
        await editAd({
          variables: {
            ad_id: ad.ad_id,
            ad_name: adName,
            copy: adCopy,
            url: adUrl,
            image: adImage,
          },
        });
        refetch();
        window.location.href = "/ads";
      } else {
        await createAd({
          variables: {
            ad_name: adName,
            copy: adCopy,
            url: adUrl,
            image: adImage,
          },
        });
        refetch();
        window.location.href = "/ads";
      }
    }
  };

  const [deleteAd] = useMutation<GraphQLTypes.DeleteAdInput>(DELETE_AD);
  const removeAd = async () => {
    await deleteAd({
      variables: {
        ad_id: ad?.ad_id,
      },
    });
    refetch();
    window.location.href = "/ads";
  };

  useEffect(() => {
    const nameTooltip = new Tooltip(
      nameTooltipRef.current !== null ? nameTooltipRef.current! : "",
      {
        title: "This is shown only to you to tell apart your ads.",
        placement: "right",
        trigger: "hover",
      }
    );
    const imageTooltip = new Tooltip(
      imageTooltipRef.current !== null ? imageTooltipRef.current! : "",
      {
        title:
          "This is the link to the image you want displayed when your ad appears.",
        placement: "right",
        trigger: "hover",
      }
    );
    const destinationTooltip = new Tooltip(
      destinationTooltipRef.current !== null
        ? destinationTooltipRef.current!
        : "",
      {
        title:
          "This is the link that you want opened when your ad is tapped on.",
        placement: "right",
        trigger: "hover",
      }
    );
    const contentTooltip = new Tooltip(
      contentTooltipRef.current !== null ? contentTooltipRef.current! : "",
      {
        title: "This is text content shown in your ad.",
        placement: "right",
        trigger: "hover",
      }
    );
    nameTooltip.enable();
    imageTooltip.enable();
    destinationTooltip.enable();
    contentTooltip.enable();
  });

  return (
    <div className="container">
      <div>
        <div className="d-flex justify-content-center">
          <AdPreview
            adImage={adImage.length > 0 ? adImage : adImagePlaceholder}
            adCopy={adCopy.length > 0 ? adCopy : adCopyPlaceholder}
          />
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            validateInput();
          }}
          className="gy-0 gx-3 text-left row"
        >
          <div className="col-md-12">
            <label htmlFor="adName" className="form-label">
              <button type="button" className="btn" ref={nameTooltipRef}>
                Ad Name{" "}
                <Icon
                  width="0.8rem"
                  height="0.8rem"
                  name="info-circle"
                  className="spacer-right input-info-icon"
                />
              </button>
              <input
                onChange={(e) => {
                  setAdName(e.target.value);
                  // validateInput();
                }}
                key="adName"
                value={adName}
                type="text"
                className={adNameClass}
                id="adName"
                placeholder={adNamePlaceholder}
                // ref={formInput}
              />
              {!adNameValid && (
                <div id="validationAdNameFeedback" className="invalid-feedback">
                  Please enter ad name.
                </div>
              )}
            </label>
          </div>

          <div className="col-md-12">
            <label htmlFor="appName" className="form-label">
              <button type="button" className="btn" ref={imageTooltipRef}>
                Image URL{" "}
                <Icon
                  width="0.8rem"
                  height="0.8rem"
                  name="info-circle"
                  className="spacer-right input-info-icon"
                />
              </button>
              <input
                onChange={(e) => {
                  setAdImage(e.target.value);
                  // validateInput();
                }}
                key="appName"
                value={adImage}
                type="text"
                className={adImageClass}
                id="adImage"
                placeholder={adImagePlaceholder}
                // ref={formInput}
              />
              {!adImageValid && (
                <div
                  id="validationAdImageFeedback"
                  className="invalid-feedback"
                >
                  Please enter a valid image URL i.e.
                  https://example.com/image.jpg
                </div>
              )}
            </label>
          </div>
          <div className="col-md-12">
            <label htmlFor="appName" className="form-label">
              <button type="button" className="btn" ref={destinationTooltipRef}>
                Destination URL{" "}
                <Icon
                  width="0.8rem"
                  height="0.8rem"
                  name="info-circle"
                  className="spacer-right input-info-icon"
                />
              </button>
              <input
                onChange={(e) => {
                  setAdUrl(e.target.value);
                  // validateInput();
                }}
                key="appName"
                value={adUrl}
                type="text"
                className={adUrlClass}
                id="appName"
                placeholder={adDestinationPlaceholder}
                // ref={formInput}
              />
              {!adUrlValid && (
                <div id="validationAdUrlFeedback" className="invalid-feedback">
                  Please enter a valid destination URL i.e. https://example.com
                </div>
              )}
            </label>
          </div>

          <div className="col-12">
            <label htmlFor="appleID" className="form-label">
              <div className="row">
                <div className="col">
                  <button type="button" className="btn" ref={contentTooltipRef}>
                    Content{" "}
                    <Icon
                      width="0.8rem"
                      height="0.8rem"
                      name="info-circle"
                      className="spacer-right input-info-icon"
                    />
                  </button>
                </div>
                <div className="col text-end">
                  <span className={textLengthClass}>{adCopy.length}</span>/
                  {ALLOWED_LENGTH}
                </div>
              </div>
              <textarea
                key="appleId"
                value={adCopy}
                className={adCopyClass}
                id="appleID"
                placeholder={adCopyPlaceholder}
                onChange={(e) => {
                  setAdCopy(e.target.value);
                  // validateInput();
                }}
              />
              {!adCopyValid && (
                <div
                  id="validationAdImageFeedback"
                  className="invalid-feedback"
                >
                  Please enter ad text.
                </div>
              )}
            </label>
          </div>

          <div className="d-flex w-100 align-items-center justify-content-between">
            <button className="btn btn-success" type="submit">
              {ad === undefined ? "Create" : "Update"}
            </button>

            <div>
              {ad !== undefined && (
                <button
                  className="btn btn-danger"
                  type="button"
                  onClick={() => {
                    const result = window.confirm(
                      "Are you sure you want to delete this ad?"
                    );
                    if (result) {
                      console.log("delete here");
                      removeAd();
                    }
                  }}
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdForm;
