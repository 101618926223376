import "../../main.scss";
import { App } from "../../generated/graphql";
import AppTableRow from "./AppTableRow";

/**
 * Primary UI component for user interaction
 */

const DUMMY_DATA = [
  {
    app_id: "1",
    app_name: "Apollo",
    api_key: "7",
  },
];

export interface AppTableProps {
  apps: App[];
}

const AppTable = ({ apps = DUMMY_DATA }: AppTableProps) => (
  <table className="table">
    <thead>
      <tr>
        <th scope="col">App</th>
        <th scope="col">API Key</th>
        <th scope="col">Apple ID</th>
      </tr>
    </thead>
    <tbody>
      {apps?.map((app) => (
        <AppTableRow app={app} key={app.app_id} />
      ))}
    </tbody>
  </table>
);

export default AppTable;
