import ProgressIndicator from "../../assets/progress_indicator.svg";
import { ALLOWED_LENGTH } from "./AdConstants";

interface AdPreviewProps {
  adImage: string;
  adCopy: string;
}

const AdPreview = ({ adImage, adCopy }: AdPreviewProps) => (
  <div className="d-flex ad-container">
    <div className="flex-shrink-0">
      <img src={adImage} alt="..." className="ad-image" />
    </div>
    <div className="flex-grow-1 ms-3 ad-text">
      <p className="mb-0 mt-1 ad-text">{adCopy.substring(0, ALLOWED_LENGTH)}</p>
      <p className="ad-text-source text-end">
        Not Evil <span className="fw-bold">Ads</span>
      </p>
    </div>
    <img
      className="ad-progress-circle"
      src={ProgressIndicator}
      alt="Advertisement progress circle showing time remaining on advertisement"
    />
  </div>
);
export default AdPreview;
