import { useState, useEffect, MutableRefObject } from "react";
import "../../main.scss";
import Button from "../Button";

// Modified from here https://stackoverflow.com/a/50980203
export interface ModalProps {
  button?: { label: string; icon: string; class: string };
  details?: { title: string; dismiss: string; save: string };
  onSave?: () => void;
  inputRef?: MutableRefObject<any>;
  children?: JSX.Element;
}

const Modal = ({
  button = {
    label: "Filters",
    icon: "",
    class: "btn btn-danger",
  },
  details = {
    title: "Filters",
    dismiss: "Close",
    save: "Apply",
  },
  onSave = () => {},
  inputRef,
  children,
}: ModalProps) => {
  const [isOpen, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  const handleClick = (e) => {
    if (e.target !== e.currentTarget) {
      return;
    }
    toggleOpen();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress, false);
    inputRef?.current.focus();

    return () => {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, [isOpen]);

  const apply = () => {
    if (onSave !== null) {
      onSave();
    }
    toggleOpen();
  };

  return (
    <div>
      <Button
        label={button.label}
        icon={button.icon}
        buttonClass={button.class}
        clickHandler={toggleOpen}
      />

      <div
        className={isOpen ? "modal fade show" : "modal fade"}
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden={!!isOpen}
        aria-modal={!!isOpen}
        style={{ display: isOpen ? "block" : "none" }}
        onClick={handleClick}
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {details.title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={toggleOpen}
              />
            </div>
            <div className="modal-body">{children}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={toggleOpen}
              >
                {details.dismiss}
              </button>
              <button type="button" className="btn btn-success" onClick={apply}>
                {details.save}
              </button>
            </div>
          </div>
        </div>
      </div>
      {isOpen ? <div className="modal-backdrop fade show" /> : null}
    </div>
  );
};

export default Modal;
