import { gql } from "@apollo/client";

export const CATEGORY_AND_ADS_QUERY = gql`
  query {
    categories {
      category_id
      category_name
    }
    ads {
      ad_id
      ad_name
      url
      image
      copy
    }
  }
`;

export const EDIT_APP_ADVERTISING = gql`
  mutation EditAppAdvertising(
    $appId: String!
    $advertising: Boolean!
    $crossPromotion: Boolean!
    $genreBlockListIds: [String]
    $selectedAdId: Int
  ) {
    editAppAdvertising(
      app_id: $appId
      advertising: $advertising
      cross_promotion: $crossPromotion
      blocklist_categories_ids: $genreBlockListIds
      ad_id: $selectedAdId
    ) {
      app_id
      advertising
      cross_promotion
      ad {
        ad_id
      }
      blocklist_categories {
        category_id
      }
    }
  }
`;
