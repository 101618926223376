import "../main.scss";

export interface ContainerProps {
  children: JSX.Element | string;
}

const Container = ({ children }: ContainerProps): JSX.Element => (
  <div className="container-fluid">
    <div className="row">
      {/* <nav
        id="sidebarMenu"
        className="col-md-3 col-lg-2 d-md-block sidebar collapse"
      >
        <div className="position-sticky pt-3 sticky-top">
          <ul className="nav flex-column">
            <a className="navbar-brand logotype" href="/">
              <span className="logotype-ads">not evil</span> ads
            </a> */}

      {/* <SidebarItem label="Dashboard" icon="house" destination="/" /> */}

      {/* <SidebarHeading>Advertising</SidebarHeading> */}

      {/* <SidebarItem
              label="Campaigns"
              icon="bullseye"
              destination="/campaigns"
            /> */}

      {/* <SidebarItem label="Apps" icon="grid" destination="/apps" />
            <SidebarItem label="Ads" icon="easel" destination="/ads" /> */}

      {/* <SidebarItem
              label="Discover"
              icon="binoculars"
              destination="/discover"
            />

            <SidebarHeading>Publishing</SidebarHeading> */}
      {/* </ul>
        </div>
      </nav> */}

      <main className="">
        <div className="p-3">{children}</div>
      </main>
    </div>
  </div>
);

export default Container;
