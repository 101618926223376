import Pie, { PieArcDatum } from "@visx/shape/lib/shapes/Pie";
import { ParentSize } from "@visx/responsive";
import { Group } from "@visx/group";
import { scaleOrdinal } from "@visx/scale";

import { Datum } from "../../../generated/graphql";

function getLabelPos(
  arc: PieArcDatum<Datum>,
  outerRadius: number,
  innerRadius: number
) {
  const r = (outerRadius + innerRadius) / 2;
  const a = (+arc.startAngle + +arc.endAngle) / 2 - Math.PI / 2;
  return [Math.cos(a) * r, Math.sin(a) * r];
}

export interface PieChartLabelOptions {
  showName?: boolean;
  showValue?: boolean;
  showPercent?: boolean;
}

interface PieLabelProps {
  arc: PieArcDatum<Datum>;
  outerRadius: number;
  innerRadius: number;
  labelOptions: PieChartLabelOptions;
  total: number;
}

const PieLabel = ({
  arc,
  outerRadius,
  innerRadius,
  labelOptions,
  total,
}: PieLabelProps) => {
  const labelRadius = innerRadius === 0 ? outerRadius / 6 : innerRadius;
  const [labelX, labelY] = getLabelPos(arc, outerRadius, labelRadius);
  const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.3;

  if (!hasSpaceForLabel) {
    return null;
  }

  const labelFontSize = labelOptions.showName
    ? Math.min(Math.max((outerRadius / 150) * 14, 12), 30)
    : Math.min(Math.max((outerRadius / 100) * 14, 12), 36);

  return (
    <g>
      <text
        fill="white"
        x={labelX}
        y={labelY}
        dy=".33em"
        fontSize={labelFontSize}
        textAnchor="middle"
        pointerEvents="none"
      >
        {labelOptions.showName && (
          <tspan x={labelX} dy="1.2em">
            {arc.data.label}
          </tspan>
        )}
        {labelOptions.showValue && (
          <tspan x={labelX} dy="1.2em">
            {arc.data.value}
          </tspan>
        )}
        {labelOptions.showPercent && (
          <tspan x={labelX} dy="1.2em">
            {`${((arc.data.value ?? 0 / total) * 100).toFixed(0)}%`}
          </tspan>
        )}
      </text>
    </g>
  );
};

interface PieProps {
  height: number;
  width: number;
  data: Datum[];
}

// const data: Datum[] = [
//   { label: "iPad Mini 4", value: 30 },
//   { label: "iPhone 12", value: 40 },
//   { label: "iPhone XR", value: 50 },
//   { label: "iPad Pro", value: 61 },
//   { label: "iPhone 12 Pro Max", value: 68 },
//   { label: "iPhone 11", value: 72 },
//   { label: "iPod Touch 7", value: 112 },
//   { label: "iPhone 9", value: 115 },
//   { label: "iPad Mini 3", value: 128 },
//   { label: "iPad Air 2", value: 156 },
//   { label: "iPod Classic", value: 178 },
//   { label: "iPhone X", value: 190 },
//   { label: "iPhone XS", value: 164 },
//   { label: "iPad Air", value: 132 },
//   { label: "iPhone 13", value: 180 },
// ];

const PieChart = ({ height, width, data }: PieProps) => {
  const innerWidth = width;
  const innerHeight = height;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 50;

  const total = data.reduce((a, b) => a + (b.value ?? 0), 0);

  const usage = (d: Datum) => d.value ?? 0;

  const getColor = scaleOrdinal({
    domain: data.map((l) => l.value ?? 0),
    range: [
      "#ddd6fe",
      "#c4b5fd",
      "#a78bfa",
      "#8b5cf6",
      "#7c3aed",
      "#6d28d9",
      "#5b21b6",
      "#4c1d95",
    ],
  });

  return (
    <svg width={width} height={height}>
      <rect rx={25} width={width} height={height} fill="transparent" />
      <Group top={centerY} left={centerX}>
        <Pie
          data={data}
          pieValue={usage}
          outerRadius={radius}
          innerRadius={radius - donutThickness}
          cornerRadius={3}
          padAngle={0.005}
          centroid={(xy, arc) => {
            console.log(xy);
            console.log(arc);
            return (
              <text
                fill="#000"
                x={xy[0]}
                y={xy[1]}
                dy=".33em"
                fontSize={9}
                textAnchor="middle"
                pointerEvents="none"
              >
                {arc.value}
              </text>
            );
          }}
        >
          {/* {(pie) => {
            console.log(pie);
            return pie.arcs;
          }} */}
          {(pie) =>
            pie.arcs.map((arc) => (
              <g
                key={arc.data.label}
                //  className={styles.svgArg}
                //  onMouseMove={(event) => onMouseMoveOverArc(event, arc.data)}
                //  onMouseOut={hideTooltip}
              >
                <path
                  d={pie.path({ ...arc })!}
                  fill={getColor(arc.data.value ?? 0)}
                  //    stroke={theme.colors.panelBg}
                  strokeWidth={1}
                />
                <PieLabel
                  arc={arc}
                  outerRadius={radius}
                  innerRadius={radius - donutThickness}
                  labelOptions={{ showName: true }}
                  total={total}
                />
                )
              </g>
            ))
          }
        </Pie>
      </Group>
    </svg>
  );
};

export interface ResponsivePieChartProps {
  data: Datum[];
}

const ResponsivePieChart = ({ data }: ResponsivePieChartProps) => (
  <ParentSize>
    {(parent) => (
      <PieChart height={parent.height} width={parent.width} data={data} />
    )}
  </ParentSize>
);

export default ResponsivePieChart;
