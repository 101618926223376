import { useState, useRef } from "react";

import { gql, useMutation } from "@apollo/client";

import * as GraphQLTypes from "../../generated/graphql";

import Modal from "../modal/Modal";

const EDIT_APP = gql`
  mutation EditApp($app_id: String!, $app_name: String!, $apple_id: String) {
    editApp(app_id: $app_id, app_name: $app_name, apple_id: $apple_id) {
      app_name
    }
  }
`;

interface EditAppFormProps {
  refetch?: () => void;
  appId: string;
  propAppName: string;
  propAppleId: string;
}

const EditAppForm = ({
  refetch = () => {},
  appId = "",
  propAppName = "",
  propAppleId = "",
}: EditAppFormProps): JSX.Element => {
  const button = {
    label: "Edit",
    icon: "",
    class: "btn btn-outline-primary",
  };
  const details = {
    title: "Edit app",
    dismiss: "Cancel",
    save: "Save",
  };

  const [appName, setAppName] = useState(propAppName);
  const [appleId, setAppleId] = useState(propAppleId);

  const [editApp] = useMutation<GraphQLTypes.EditAppInput>(EDIT_APP);

  const modifyApp = async () => {
    await editApp({
      variables: { app_id: appId, app_name: appName, apple_id: appleId },
    });
    refetch();
  };

  const formInput = useRef(null);

  return (
    <div>
      <Modal
        button={button}
        details={details}
        onSave={() => {
          modifyApp();
        }}
        inputRef={formInput}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            modifyApp();
          }}
          className="g-3 text-left"
        >
          <div className="col-12">
            <label htmlFor="appName" className="form-label">
              App Name
              <input
                onChange={(e) => {
                  setAppName(e.target.value);
                }}
                key="appName"
                value={appName}
                type="text"
                className="form-control"
                id="appName"
                placeholder="Best App" // TODO: fun random name generator
                ref={formInput}
              />
            </label>
          </div>

          <div className="col-12">
            <label htmlFor="appleID" className="form-label">
              Apple ID (from App Store page)
              <input
                key="appleId"
                type="text"
                value={appleId}
                className="form-control"
                id="appleID"
                placeholder="1495577605"
                onChange={(e) => {
                  setAppleId(e.target.value);
                }}
              />
            </label>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default EditAppForm;
