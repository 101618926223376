import { gql, useMutation } from "@apollo/client";

import * as GraphQLTypes from "../../generated/graphql";

import Modal from "../modal/Modal";

const CHECK_DOMAIN = gql`
  mutation CheckDomain($domain_name: String) {
    checkDomain(domain_name: $domain_name) {
      domain_name
      token
      verified
    }
  }
`;

interface DomainInfoProps {
  refetch?: () => void;
  domainName: string;
  token: string;
  verified: boolean;
  apple_id: string;
}

const DomainInfo = ({
  refetch = () => {},
  domainName = "",
  token = "",
  verified = false,
  apple_id = "",
}: DomainInfoProps): JSX.Element => {
  let button = {
    label: "Pending",
    icon: "exclamation-triangle",
    class: "btn btn-outline-warning ms-3",
  };
  let details = {
    title: "Verification pending",
    dismiss: "Cancel",
    save: "OK",
  };

  if (verified) {
    button = {
      label: "Verified",
      icon: "check-circle",
      class: "btn btn-outline-success ms-3",
    };
    details = {
      title: "Verification complete",
      dismiss: "Cancel",
      save: "OK",
    };
  }

  const [checkDomain] = useMutation<GraphQLTypes.EditAppInput>(CHECK_DOMAIN);

  const triggerCheckDomain = async () => {
    if (apple_id !== "" || domainName !== "") {
      await checkDomain({
        variables: { domain_name: domainName },
      });
      refetch();
    }
  };

  let message: string;

  if (apple_id === "") {
    message =
      "Please follow the instructions here to verify your app and domain.";
  } else if (domainName === "") {
    message =
      "No marketing website found on App Store page. Please follow the instructions here to verify your app and domain.";
  } else if (!verified) {
    message = `Token not found at ${domainName}. Please follow the instructiones here, or recheck for token now.`;
    details.save = "Check";
  } else {
    message = `${domainName} successfully verified.`;
  }

  return (
    <div>
      <Modal
        button={button}
        details={details}
        onSave={() => {
          triggerCheckDomain();
        }}
      >
        <div className="text-break">
          <p>{message}</p>
          {token.length > 0 ? (
            <div>
              {" "}
              <p>Your token is:</p>
              <p className="font-monospace">{token}</p>
            </div>
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default DomainInfo;
