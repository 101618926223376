import {
  Axis,
  Grid,
  AreaSeries,
  XYChart,
  Tooltip,
  lightTheme as lt,
  buildChartTheme,
} from "@visx/xychart";

import { ThemeConfig } from "@visx/xychart/lib/theme/buildChartTheme";
import { ParentSize } from "@visx/responsive";
import { LinearGradient } from "@visx/gradient";
import { curveMonotoneX } from "@visx/curve";

import { Datum } from "../../../generated/graphql";

const lightTheme = buildChartTheme({
  ...lt,
  colors: ["#7c3aed"],
} as unknown as ThemeConfig);

const accessors = {
  xAccessor: (d: Datum) => d.label,
  yAccessor: (d: Datum) => d.value,
};

export interface LineChartProps {
  data: Datum[];
}

const LineChart = ({ data }: LineChartProps) => (
  <ParentSize>
    {(parent) => (
      <XYChart
        height={parent.height}
        width={parent.width}
        xScale={{ type: "band" }}
        yScale={{ type: "linear" }}
        theme={lightTheme}
      >
        <LinearGradient
          id="area-gradient"
          from="#7c3aed"
          to="#7c3aed"
          toOpacity={0.1}
        />
        <Axis orientation="bottom" numTicks={3} hideAxisLine hideTicks />
        <Axis orientation="left" numTicks={10} hideAxisLine hideTicks />
        <Grid columns={false} numTicks={10} />
        <AreaSeries
          dataKey="Line 2"
          data={data}
          xAccessor={accessors.xAccessor}
          yAccessor={accessors.yAccessor}
          fillOpacity={0.4}
          fill="url(#area-gradient)"
          stroke="url(#area-gradient)"
          lineProps={{
            style: { strokeWidth: 3, color: "#7c3aed" },
          }}
          curve={curveMonotoneX}
        />
        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          showVerticalCrosshair
          showSeriesGlyphs
          renderTooltip={({ tooltipData, colorScale }) => {
            if (!colorScale) throw Error("invalid colorscale");
            if (!tooltipData) throw Error("invalid tooltip");
            const nearest = tooltipData.nearestDatum;
            if (!nearest) throw Error("invalid nearest");

            return (
              <div>
                <div style={{ color: colorScale(nearest.key) }}>
                  {nearest.key}
                </div>
                {accessors.xAccessor(nearest.datum as Datum)}
                {", "}
                {accessors.yAccessor(nearest.datum as Datum)}
              </div>
            );
          }}
        />
      </XYChart>
    )}
  </ParentSize>
);

export default LineChart;
