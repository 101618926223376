import "../../main.scss";

import { Link } from "react-router-dom";

import draft from "../../assets/img/Draft App Icon.jpg";

import { App } from "../../generated/graphql";

interface AppTableRowProps {
  app: App;
}

const AppTableRow = ({ app }: AppTableRowProps) => (
  // <tr key={key}>
  <tr>
    <td>
      <Link to={`/apps/${app.app_id}`}>
        <div>
          <img
            src={(app.image_url?.length ?? 0) > 0 ? app.image_url! : draft}
            alt={`${app.app_name} logo`}
            className="app-image"
          />
          {app.app_name}
        </div>
      </Link>
    </td>
    <td>{app.api_key}</td>
    <td>{(app.apple_id?.length ?? 0) > 0 ? app.apple_id : "No ID set"}</td>
  </tr>
);
export default AppTableRow;
