import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";

// Be careful with this. Its only a soft protection. Actual data should be secured behind an API
const ProtectedRoute = ({
  component,
  path,
}: {
  component: React.ComponentType;
  path: string;
}) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => <div>Loading...</div>,
    })}
    path={path}
  />
);

export default ProtectedRoute;
